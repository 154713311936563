<template>
  <div class="relative overflow-hidden z-0 h-full">
    <div v-if="!isLoaded" class="flex md:w-full gap-x-5 md:gap-x-[50px] pl-6 md:pl-0 overflow-hidden">
      <OfferBullet
        v-for="(item, index) in items"
        :key="index"
        :title="item?.title ?? ''"
        :description="item?.description ?? ''"
        :image="item?.image ?? ''"
        :href="item?.href ?? ''"
        :lazy="true"
        as="AppLink"
        class="md:hidden OfferBullet rounded-4xs overflow-hidden h-[240px] flex-shrink-0"
      />

      <OfferBullet
        v-for="(item, index) in items"
        :key="index"
        class="hidden md:flex rounded-6xs overflow-hidden h-full"
        :mobile-image="index === 0 ? '' : item?.mobileImage"
        :title="index === 0 ? '' : (item?.title ?? '')"
        :description="index === 0 ? '' : (item?.description ?? '')"
        :image="index === 0 ? '' : (item?.image ?? '')"
        :href="item?.href ?? ''"
        :lazy="true"
        as="a"
        :class="{
          'Offer-bullet': index === 0,
        }"
      >
        <template v-if="index === 0" #image>
          <picture>
            <AppSource
              :srcset="`${item?.mobileImage ?? item?.image} 480w, ${item?.image} 800w, ${item?.image} 1200w`"
              width="280"
              height="320"
              format="avif"
              fit="contain"
              :lazy="true"
            />
            <AppSource
              :srcset="`${item?.mobileImage ?? item?.image} 480w, ${item?.image} 800w, ${item?.image} 1200w`"
              width="278"
              height="320"
              format="webp"
              fit="contain"
              :lazy="true"
            />
            <AppSource
              :srcset="`${item?.mobileImage ?? item?.image} 480w, ${item?.image} 800w, ${item?.image} 1200w`"
              width="278"
              height="320"
              format="jpeg"
              fit="contain"
              :lazy="true"
            />

            <AppImage
              :src="item?.image || ''"
              :alt="item?.title ?? ''"
              class="object-cover -mt-5 h-[84px] w-[227px] absolute top-1/2 transform -translate-y-1/2 -right-5"
              width="278"
              height="84"
            />
          </picture>
        </template>

        <template v-if="index === 0" #description>
          <span class="font-display max-w-[220px] font-bold text-lg text-primary-A900 [ absolute bottom-7 left-7 ]">
            {{ item?.description }}
          </span>
        </template>
      </OfferBullet>
    </div>
    <div v-show="isLoaded" class="relative overflow-hidden z-0 h-full">
      <Swiper
        :modules="[SwiperAutoplay, SwiperNavigation]"
        :slides-per-view="mobileSlidesPerView"
        :space-between="20"
        :breakpoints="breakpoints"
        :centered-slides="false"
        :center-insufficient-slides="true"
        :loop="false"
        :lazy="true"
        :autoplay="autoPlay ? { delay: autoPlay } : false"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        class="h-full"
      >
        <SwiperSlide v-for="(item, index) in items" :key="index">
          <slot
            :item="{
              index,
              id: item?.id || 0,
              image: item?.image || '',
              mobileImage: item?.mobileImage || '',
              ...item,
            }"
          />
        </SwiperSlide>
        <SwiperControls @is-loaded="isSwiperLoaded" />
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Unpacked } from '@robustastudio/e-commerce/common';
import type { HomeQuery } from '~/graphql/Home';

const isLoaded = ref(false);

const isSwiperLoaded = () => {
  isLoaded.value = true;
};

const props = defineProps({
  items: {
    type: Array as PropType<
      {
        image?: string;
        description?: string;
        href?: string;
      }[]
    >,
    required: true,
  },
  type: {
    type: String,
    default: 'primary',
  },
  autoPlay: {
    type: Number,
    default: 0,
  },
  mobileSlidesPerView: {
    type: Number,
    default: 1.5,
  },
  desktopSlidesPerView: {
    type: Number,
    default: 3,
  },
});

defineSlots<{
  default(props: {
    item: (Unpacked<HomeQuery['homeOffers']> | null) & {
      index: number;
    };
  }): any;
}>();

const items = toRef(props, 'items');

const breakpoints = {
  '400': {
    slidesPerView: props.mobileSlidesPerView,
    spaceBetween: 20,
    slidesOffsetBefore: 20,
    loop: false,
  },
  '768': {
    slidesPerView: props.desktopSlidesPerView,
    spaceBetween: 50,
    slidesOffsetBefore: 0,
    loop: false,
  },
};
</script>

<style lang="postcss" scoped>
ul {
  .nuxt-link-exact-active {
    @apply relative font-bold text-primary-700;
    &::after {
      @apply bg-secondary-400 absolute;
      content: '';
      height: 9px;
      width: calc(100% + 0.5rem);
      bottom: -4px;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
}

.category-scroll-list {
  @apply px-5;
  /* 40px is the padding on either side */
  width: calc(100vw);
}

.-scale-x-100 {
  transform: scaleX(-1);
}

.OfferBullet {
  width: 86%;
}

.Offer-bullet {
  border-radius: 277.773px 277.773px 167.693px 167.693px;
  background: linear-gradient(225deg, #b3bfff 0%, #bac9ff 51.05%, #f4c9eb 100%);
}
</style>
