<template>
  <div class="commercialProducts">
    <header class="text-left flex items-center">
      <MainTitle align="left" :inline="false" class="text-center md:text-left">
        <template #before>
          {{ $t('commercialProducts') }}
        </template>
        {{ $t('forBusiness') }}
      </MainTitle>
    </header>

    <OfferBulletSlider v-slot="{ item }" class="[ mt-5 lg:mt-0 ] h-56" :items="commercialCategories" type="secondary">
      <OfferBullet
        :image="item.image"
        :description="item.description || ''"
        :href="item.href || ''"
        :lazy="true"
        as="AppLink"
        class="rounded-6xs overflow-hidden text-2xl"
      />
    </OfferBulletSlider>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
const { findBySlug } = await useCategories();

const commercialCategories = computed(() => {
  return (
    findBySlug('commercial')?.children?.map(child => ({
      image: child.image || '',
      description: child.name || '',
      href: `/commercial/${child.url_key}`,
    })) ?? []
  );
});
</script>
<i18n>
{
    "en": {
        "title": "Commercial Products For Business",
        "commercialProducts": "Commercial Products",
        "forBusiness": "For Business"
    },
    "ar": {
        "title": "المنتجات التجارية للأعمال",
        "commercialProducts": "منتجات ",
        "forBusiness": "تجارية"
    }
}
</i18n>
<style lang="postcss" scoped>
@screen lg {
  .commercialProducts {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 57px;
  }
}

.highlight {
  @apply relative inline;
  &::after {
    content: '';
    background: url('~/assets/icons/path.svg');
    position: absolute;
    bottom: -15px;
    right: -16px;
    height: 55px;
    width: 126px;
    z-index: -1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  @screen lg {
    &::after {
      content: '';
      background: url('~/assets/icons/path.svg');
      position: absolute;
      bottom: -33px;
      right: -16px;
      height: 124px;
      width: 300px;
      z-index: -1;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
